.bg-faq{
    background-image: url("../assets/images/faq/faqBanner.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; 
   }

   @media (max-width: 1023px) {
    .boxes-faq {
      display: block;
      white-space: nowrap;
    }
  
    .boxes-faq {
      max-width: auto;
      overflow-x: auto;
    }
  }