.bg-contactus {
  background-image: url("../assets/images/contactUs/contactus-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.toll-free {
  background: transparent linear-gradient(108deg, #67ce95 0%, #289dc2 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}
