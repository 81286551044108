table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}
th {
  background-color: #e2e7eb;
}
td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: white;
}

@media (max-width: 1023px) {
  .boxes-advantages {
    display: block;
    white-space: normal;
  }

  .boxes-advantages {
    overflow-x: auto;
  }
}


@media (max-width: 1023px) {
  table {
    display: block;
    white-space: nowrap;
  }

  th,
  td {
    max-width: auto;
    padding: 8px;
    overflow-x: auto;
  }
}
@media (max-width: 767px) and (min-width: 440px) {
  .advantages-sidenav{
    min-width: 50%;
  }
}
@media (max-width: 439px) and (min-width: 320px) {
  .advantages-sidenav{
    min-width: 85%;
  }
}

.snake-bg {
  background-image: url("../assets/images/advantages/Shape.ef1edffb.svg");
  background-size: 100% 90%;
  background-repeat: no-repeat;
  background-position: 40px 100px;
}

@media (max-width: 1023px) and (min-width: 320px) {
  .snake-bg {
    background: white;
  }
}