.bg-process{
    background-image: url("../assets/images/process/processBanner.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.snake-bg-process{
    background-image: url("../assets/images/advantages/Shape.ef1edffb.svg");
    background-size: 100% 80%;
    background-repeat: no-repeat;
    background-position: 40px 100px;
  }
  
  @media (max-width: 1023px) and (min-width: 320px) {
    .snake-bg-process{
      background: white;
    }
  }