/* tailwind configurations */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* css files imports */
@import url("./layout.css");
@import url("./contactUs.css");
@import url("./homepage.css");
@import url("./comingsoon.css");
@import url("./home.css");
@import url("./cookie.css");
@import url("./result.css");
@import url("./process.css");
@import url("./advantages.css");
@import url("./whoWeAre.css");
@import url("./renovates.css");
@import url("./faq.css");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

/* common box shadow */
.box-shadow1 {
  box-shadow: 0px 0px 20px #00000010;
}
.checkbox-group-custom .ant-checkbox-group-item {
  margin: 0 !important;
}
.ant-slider-tooltip {
  z-index: 20 !important;
}
.input-value-container input,
:where(.css-dev-only-do-not-override-ed5zg0).ant-input-number-disabled
  .ant-input-number-input {
  text-align: center !important;
  color: #007ddf !important;
}
.radio-group-aligment .ant-radio {
  align-self: start !important;
}
