
table {
  font-family: "Poppins";
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th {
  text-align: left;
  padding: 8px;
  font-weight: 500;
  font-size: 14px;
}
td {
  text-align: left;
  padding: 8px;
  font-size: 14px;
  color: #465259;
  font-weight: normal;
  
}
tr:nth-child(even) {
  background-color: #dddddd;
}

@media (max-width: 1023px) {
  table {
    display: block;
    white-space: nowrap;
  }
  
  th,
  td {
    max-width: auto;
    padding: 8px;
    overflow-x: auto;
  }
}