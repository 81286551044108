.bg-home {
  background-image: url("../assets/images/homepage/background-house.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.font-popins{
  font-family: "popins"!important;
}
.shadow-positive-points{
  box-shadow: 0px 0px 10px #00000014;
}