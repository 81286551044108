@media (max-width: 1023px) {
  .boxes {
    display: block;
    white-space: nowrap;
  }

  .boxes {
    max-width: auto;
    overflow-x: auto;
  }
}
