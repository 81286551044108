.result_common_model_container_header .ant-input-group-addon {
  display: none !important;
}
.result_common_model_container_header .ant-select-auto-complete {
  width: 100% !important;
}
.result_common_model_container .ant-input-group-addon {
  display: none !important;
}
.result_common_model_container
  input.ant-input.ant-input-lg.css-dev-only-do-not-override-ed5zg0 {
  border-radius: 0px !important;
  /* height: 64.9px !important; */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 0px 10px #00000019; */
  border-radius: 4px;
}
.ttnc-autocomplete-search .ant-input {
  border-radius: 0px !important;
  height: 64.9px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000019;
  border-radius: 4px;
}
.result_common_model_container_header
  input.ant-input.ant-input-lg.css-dev-only-do-not-override-ed5zg0,
.result_common_model_container_header .css-ed5zg0 {
  border-radius: 0px !important;
  height: 50px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000019;
  border-radius: 4px;
}
.result_common_model_container .ant-select-auto-complete {
  width: 100% !important;
}
/* bg image css starts here */
@media (max-width: 845px) and (min-width: 768px) {
  .our-clients-container .margin-left-right {
    margin: 0px 5% 0px 0px;
  }
}
@media (max-width: 900px) and (min-width: 846px) {
  .our-clients-container .margin-left-right {
    margin: 0px 5% 0px 15px;
  }
}
@media (max-width: 940px) and (min-width: 901px) {
  .our-clients-container .margin-left-right {
    margin: 0px 5% 0px 40px;
  }
}
@media (max-width: 1023px) and (min-width: 941px) {
  .our-clients-container .margin-left-right {
    margin: 0px 5% 0px 8%;
  }
}
/*  */
@media (max-width: 1535px) and (min-width: 1045px) {
  /* .top-control1 {
    top: 1160px;
    width: 85%;
  } */
  /* .top-control2 {
    top: 1250px;
    width: 75%;
  } */
  .top-control3 {
    top: 365px !important;
    /* width: 75%; */
  }
  /* .top-control4 {
    top: 1390px;
    width: 75%;
  } */
}
@media (max-width: 767px) and (min-width: 504px) {
  .top-control1 {
    top: 1065px;
    width: 60%;
  }
  .top-control2 {
    top: 1145px;
    width: 55%;
  }
  .top-control3 {
    top: 1218px;
    width: 55%;
  }
  .top-control4 {
    top: 1290px;
    width: 55%;
  }
}
@media (max-width: 503px) and (min-width: 444px) {
  .top-control1 {
    top: 1140px;
    width: 66%;
  }
  .top-control2 {
    top: 1220px;
    width: 55%;
  }
  .top-control3 {
    top: 1290px;
    width: 50%;
  }
  .top-control4 {
    top: 1365px;
    width: 50%;
  }
}
@media (max-width: 443px) and (min-width: 341px) {
  .top-control1 {
    top: 1160px;
    width: 85%;
  }
  .top-control2 {
    top: 1250px;
    width: 75%;
  }
  .top-control3 {
    top: 1322px;
    width: 75%;
  }
  .top-control4 {
    top: 1390px;
    width: 75%;
  }
}
@media (max-width: 340px) and (min-width: 320px) {
  .top-control1 {
    top: 1265px;
    width: 85%;
  }
  .top-control2 {
    top: 1330px;
    width: 75%;
  }
  .top-control3 {
    top: 1400px;
    width: 66%;
  }
  .top-control4 {
    top: 1470px;
    width: 66%;
  }
}
/* bg image css ends here */
/* our Clients css starts here */
.background-image {
  background-image: url("../assets/images/home/filigran-background.svg");
  background-position: initial;
  background-size: contain;
  background-repeat: no-repeat;
}
.line-img {
  background-image: url("../assets/images/home/line.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.house-bg {
  background-image: url("../assets/images/home/house.svg");
  background-repeat: no-repeat;
}
.house-bg1 {
  background-image: url("../assets/images/home/house1.svg");
  background-repeat: no-repeat;
  background-size: 100% 300px;
  /* background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; */
}
@media (max-width: 1199px) and (min-width: 1024px) {
  .line-img {
    background-image: url("../assets/images/home/line.svg") !important;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media (max-width: 767px) and (min-width: 660px) {
  .same-card {
    left: 150px;
  }
}
@media (max-width: 659px) and (min-width: 530px) {
  .same-card {
    left: 100px;
  }
}
@media (max-width: 529px) and (min-width: 440px) {
  .same-card {
    left: 50px;
  }
}
@media (max-width: 439px) and (min-width: 390px) {
  .same-card {
    left: 20px;
  }
}
@media (max-width: 438px) and (min-width: 350px) {
  .same-card {
    left: 0px;
  }
}
@media (max-width: 350px) and (min-width: 0px) {
  .same-card {
    left: -10px;
  }
}
@media (max-width: 1023px) and (min-width: 320px) {
  .line-img {
    background: white;
  }
}
/* our Clients css ends here */

/* positive Points css starts here */
.bg-image {
  background-image: url("../assets/images/home/positiveBg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  height: 359px;
}

/* positive Points css ends here */

/* process css starts here */
.bg-img {
  background-image: url("../assets/images/home/background-map.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 910px;
}
@media (max-width: 767px) and (min-width: 680px) {
  .louvier-box {
    margin-top: -225px;
  }
}
@media (max-width: 679px) and (min-width: 589px) {
  .louvier-box {
    margin-top: -210px;
  }
}

@media (max-width: 588px) and (min-width: 542px) {
  .louvier-box {
    margin-top: -160px;
  }
}
@media (max-width: 541px) and (min-width: 513px) {
  .louvier-box {
    margin-top: -140px;
  }
}
@media (max-width: 512px) and (min-width: 464px) {
  .louvier-box {
    margin-top: -115px;
  }
}
@media (max-width: 463px) and (min-width: 439px) {
  .louvier-box {
    margin-top: -105px;
  }
}
@media (max-width: 438px) and (min-width: 397px) {
  .louvier-box {
    margin-top: -76px;
  }
}
@media (max-width: 396px) and (min-width: 394px) {
  .louvier-box {
    margin-top: -60px;
  }
}
@media (max-width: 396px) and (min-width: 394px) {
  .louvier-box {
    margin-top: -60px;
  }
}
@media (max-width: 393px) and (min-width: 385px) {
  .louvier-box {
    margin-top: -40px;
  }
}
@media (max-width: 384px) and (min-width: 374px) {
  .louvier-box {
    margin-top: 0px;
  }
}
@media (max-width: 373px) and (min-width: 333px) {
  .louvier-box {
    margin-top: 40px;
  }
}
@media (max-width: 332px) and (min-width: 0px) {
  .louvier-box {
    margin-top: 60px;
  }
}
/* process css ends here */

/* Your side css starts here */
.line-img {
  background-image: url("../assets/images/home/line1.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.bgimg1 {
  background-image: linear-gradient(rgba(5, 19, 28, 0.5), rgba(5, 19, 28, 0.1)),
    url("../assets/images/home/process-1-modified.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bgimg2 {
  background-image: linear-gradient(rgba(5, 19, 28, 0.5), rgba(5, 19, 28, 0.1)),
    url("../assets/images/home/process-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bgimg3 {
  background-image: linear-gradient(rgba(5, 19, 28, 0.5), rgba(5, 19, 28, 0.1)),
    url("../assets/images/home/process-3.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bgline1 {
  background-image: url("../assets/images/home/big.svg");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
}
.bgimg4 {
  background-image: linear-gradient(rgba(5, 19, 28, 0.5), rgba(5, 19, 28, 0.1)),
    url("../assets/images/home/process-4.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bgline2 {
  background-image: url("../assets/images/home/smal-1.svg");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}
.bgimg5 {
  background-image: linear-gradient(rgba(5, 19, 28, 0.5), rgba(5, 19, 28, 0.1)),
    url("../assets/images/home/process-5.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bgline3 {
  background-image: url("../assets/images/home/smal-2.svg");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}

.bgline-mobile {
  background-image: url("../assets/images/home/big.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.personBgLeft {
  background-image: url("../assets/images/home/person2.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.personBgRight {
  background-image: url("../assets/images/home/person1.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #1990ff;
  border-radius: 16px;
  overflow-y: auto;
}

.boxes-main-div:hover .overlay {
  opacity: 1;
}
.boxes-main-div .overlay {
  opacity: 0;
}
.boxes-main-div-responsive {
  overflow: auto;
}
.boxes-main-div-responsive:hover .overlay {
  /* height: fit-content; */
  /* width: fit-content; */
  opacity: 1;
  overflow: auto;
  padding: 0px 10px;
}

.boxes-main-div .number-box {
  transition: width 0.7s ease-in-out;
}
.boxes-main-div:hover .number-box {
  width: 50px;
}
@media (max-width: 1023px) and (min-width: 320px) {
  .line-img {
    background: white;
  }
}

/* Your side css starts here */

/* :where(.css-dev-only-do-not-override-ed5zg0).ant-select-single{
      width: 430px !important;
  } */
.boxShaodw-homepage {
  box-shadow: 0px 2px 15px #00000025;
}
